.ability-row {
  position: relative;
  border: 1px solid var(--color-border);
  margin: -1px 0 0 -1px;
  max-width: 100%;
}

.ability-row:first-of-type::after {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.ability-row:last-of-type::after {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.ability-row:hover::after {
  margin: -1px;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  border: 1px solid var(--color-primary-hover);
  z-index: 1;
  content: '';
  pointer-events: none;
}

.ability-row:first-of-type {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.ability-row:last-of-type {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.ability-slot {
  color: var(--color-text-description);
  padding-right: 8px;
}

.ability-slot-highlight {
  text-shadow:
    0 0 1px var(--magenta),
    0 0 2px var(--magenta),
    0 0 4px var(--magenta);
}

.ability-title {
  color: var(--color-text-label);
  flex: 1;
  padding: 5px 11px 4px 11px;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.skill-description-box {
  color: var(--color-text-description);
  width: 100%;
  padding: 0 8px 8px 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.skill-targets {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}
