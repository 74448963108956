html,
body,
#root {
  overflow: hidden;
}

.root-section {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.site-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  padding: 8px;
}

.site-header > div {
  flex-direction: row;
  gap: 8px;
  justify-content: center;
}

.site-header > div:first-of-type {
  flex: 1;
  justify-content: flex-start;
}

.site-header > div:last-of-type {
  flex: 1;
  justify-content: flex-end;
}

.site-header > .user-corner {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: flex-end;
  align-items: center;
}

.site-content {
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.site-main {
  height: 100%;
  flex: 1;
}

.site-sidebar {
  height: 100%;
  min-width: 400px;
  max-width: 400px;
  background: transparent;
  display: flex;
  flex-direction: column;
}

.full-height {
  height: 100%;
}

.site-panel {
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 800px) {
  .site-sidebar {
    min-width: unset;
    max-width: unset;
  }
  .site-panel {
    display: none;
  }
  .site-panel.panel-visible:first-of-type {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .desktop-only {
    display: none;
  }
}

@media only screen and (min-width: 801px) {
  .mobile-only {
    display: none;
  }
}

.view-switcher {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.random-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 8px;
}

.random-panel-container {
  border-bottom: 1px dashed var(--color-border);
  align-self: stretch;
}

.random-panel-content {
  padding-bottom: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
}

.input-box {
  border-radius: calc(var(--border-radius) * 1px);
  border: 1px solid var(--color-border);
  height: calc(var(--control-height) * 1px);
  padding: 4px 7px;
  box-shadow: 0 2px 0 var(--color-fill-quaternary);
}

.emphasis {
  font-weight: 500;
  font-style: italic;
  color: var(--color-warning-text);
}

.inline-icon {
  height: calc(1lh - 2px);
  vertical-align: text-bottom;
  padding: 0px 4px;
}

.table-row {
  position: relative;
  border: 1px solid var(--color-border);
  margin: -1px 0 0 -1px;
  max-width: 100%;
}

.table-row:first-of-type::after {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.table-row:last-of-type::after {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.table-row:hover::after {
  margin: -1px;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  border: 1px solid var(--color-primary-hover);
  z-index: 1;
  content: '';
  pointer-events: none;
}

.table-row:first-of-type {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.table-row:last-of-type {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.ant-typography.ant-typography-disabled {
  cursor: initial;
}

.box-shadow {
  box-shadow: 5px 8px 16px 5px rgba(0, 0, 0, 0.1);
}
