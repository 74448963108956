.version-update {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.version-label {
  width: 70px;
  text-align: end;
  padding-right: 8px;
}

.version-heading {
  font-size: 16px;
  font-weight: 500;
}

.version-description {
  flex: 1;
}

.emphasis {
  font-weight: 500;
  font-style: italic;
  color: var(--color-warning-text);
}

.version-description ul {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-bottom: 1em;
  padding-inline-start: 0;
  list-style-type: none;
}

.version-description ul li {
  position: relative;
}

.version-description ul li > .anticon:first-child {
  position: absolute;
  top: 0;
  bottom: 0;
  display: block;
  padding-top: 4px;
  left: calc(-1em - 8px);
  color: var(--color-success-text);
}

.version-description aside {
  display: inline;
  color: var(--color-text-tertiary);
}

.previously-seen {
  color: var(--color-text-tertiary);
}
