.turn-simulator-view {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  height: 100%;
  overflow-y: auto;
  position: relative;
  z-index: 1;
  align-items: baseline;
  justify-content: center;
  align-content: flex-start;
}

.error-icon {
  color: var(--color-error);
  padding-right: 8px;
}

.boss-turn-card {
  margin: 8px;
  box-shadow: 5px 8px 16px 5px rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 800px) {
  .boss-turn-card {
    margin: 0;
  }
}
