.turn-row {
  cursor: default;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 2rem;
  font-weight: 500;
  color: white;
  text-shadow:
    1px 1px 3px rgba(0, 0, 0, 0.9),
    1px 1px 1px rgba(0, 0, 0, 0.65);
  padding-right: 8px;
}

.turn-row:first-of-type {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.turn-row:first-of-type img.avatar {
  border-top-left-radius: 6px;
}

.turn-row:last-of-type {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.turn-row:last-of-type img.avatar {
  border-bottom-left-radius: 6px;
}

.turn-row-highlight {
  box-shadow: 0 0 2px 8px var(--magenta);
  z-index: 1;
}

html[data-theme='light'] .turn-row-enemy {
  text-shadow:
    0px 0px 4px rgba(0, 0, 0, 0.4),
    1px 1px 3px rgba(0, 0, 0, 0.9),
    1px 1px 1px rgba(0, 0, 0, 0.65);
}
