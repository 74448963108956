.turn-meter-container {
  display: inline-flex;
  min-height: 24px;
  flex-direction: row;
  align-items: center;
}

.turn-meter-container.has-details {
  cursor: pointer;
}

.turn-meter-label {
  margin-left: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 5em;
  z-index: 2;
  padding: 0.25em;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 6px;
}

.turn-meter {
  flex: 1;
  position: relative;
  display: block;
  min-height: 10px;
  background-color: rgb(96, 96, 96);
}

.turn-meter .turn-meter-details {
  visibility: hidden;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  text-align: center;
  padding: 4px;
  overflow: visible;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: #000;
}

.turn-meter:focus-within .turn-meter-details,
.turn-meter:hover .turn-meter-details {
  visibility: visible;
}

.turn-meter .fill {
  background-color: rgba(128, 128, 128, 0.25);
}

.turn-meter .grid {
  display: grid;
  grid-template-columns: repeat(4, auto);
}

.turn-meter .cell {
  border-top: 1px solid #000;
  border-left: 1px solid #000;
  border-bottom: 1px solid #000;
}

.turn-meter .cell:last-child {
  border-right: 1px solid #000;
}

.turn-meter .filled {
  background-color: #e5d45b; /* in-game */
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
}

.turn-meter.winner .filled {
  background-color: #85b61b; /* in-game */
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
}

.turn-meter.immediate .filled {
  background-color: #00c3ff; /* in-game */
}

.turn-meter .over-filled {
  background-color: #e58b5b;
  background: linear-gradient(to right, #c21919 75%, #e58b5b);
  background-clip: content-box;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
}

.turn-meter.winner .over-filled {
  background: initial;
  background-color: #6bea22;
  background-clip: content-box;
}

.turn-meter .layer {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
}
